import { RouteConfig } from '@zenlayer/portal-utils';
import { lazy } from 'react';

export const routes: RouteConfig<any>[] = [
  {
    path: '/',
    component: lazy(() => import('@/page/status/index/index')),
  },
  {
    path: '/history',
    children: [
      {
        path: 'list',
        component: lazy(() => import('@/page/status/history/list/index')),
      },
      {
        path: 'detail',
        component: lazy(() => import('@/page/status/history/detail/index')),
      },
    ],
  },
];
