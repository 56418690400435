import { useEffect, useRef } from 'react';

export const useLoadStaticResource = (url: string) => {
  const prevLinkRef = useRef<HTMLLinkElement | null>(null);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    if (!!prevLinkRef.current) {
      link.onload = () => {
        document.head.removeChild(prevLinkRef.current!);
        prevLinkRef.current = link;
      };
    } else {
      prevLinkRef.current = link;
    }

    document.head.appendChild(link);
  }, [url]);
};
