import {
  getLangFromCMS,
  getLangFromDevStaticPath,
  getLangFromStaticPath,
} from '@/api/public/text';
import { getLocale, setLangJSON } from '@zenlayer/zen-intl18';
import { useRequest } from 'ahooks';
import { useState } from 'react';

const setLangByCMS = async () => {
  // 开发环境用本地数据合并CMS数据
  const langJSON = await getLangFromCMS();

  if (langJSON) {
    try {
      if (!__PRODUCTION__) {
        const { data } = await getLangFromDevStaticPath();
        langJSON.text = { ...langJSON.text, ...data };
      }
    } catch (e) {
      console.error(e);
    }
    setLangJSON(langJSON);
  }
};
const setLangByLocal = async () => {
  // 线上环境使用本地数据, 本地数据更新需要在CMS发布
  const locale = getLocale();
  const { data } = await getLangFromStaticPath(locale);
  const { self, inherited } = data;
  setLangJSON({
    id: 0,
    domain: locale,
    text: { ...(inherited || {}), ...(self || {}) },
  });
};
const setLangByEnv = async (isOnline: boolean) => {
  if (isOnline) {
    await setLangByLocal();
  } else {
    await setLangByCMS();
  }
};

export function getPortalEnv() {
  return localStorage.getItem('PORTAL_ENV');
}

interface RuntimeInfo {
  online: boolean;
  ga_code: string;
  location_host: string;
  has_mobile: boolean;
}
let runtimeInfo: RuntimeInfo;

export const getRuntimeInfo = () => {
  return runtimeInfo;
};
const setRuntimeInfo = (info: RuntimeInfo) => {
  runtimeInfo = info;
};

export default function useRuntimeInfo() {
  const [ready, setReady] = useState(false);

  useRequest(async () => {
    let success, data;
    if (!__PRODUCTION__) {
      window._zen_env_ = {
        online: false,
        ga_code: 'GTM-PPCTK62',
        has_mobile: false,
        location_host: 'localhost',
        public_path: '/',
      };
    }

    success = true;
    const { _zen_env_ } = window;

    if (success) {
      let env = __PRODUCTION__
        ? _zen_env_.online
          ? 'ONLINE'
          : 'TEST'
        : 'DEVELOPMENT';

      localStorage.setItem('PORTAL_ENV', env);
      // loadGtmScript(data.ga_code));
      await setLangByEnv(_zen_env_.online);
      setRuntimeInfo(_zen_env_);
      setReady(true);
    }
  });

  return {
    ready,
  };
}
