import '@zenlayer/zen-design/esm/style/themes/index.css';
import { createRoot } from 'react-dom/client';
import App from './App';
import './global.less';
// import { serviceLandingPerformance } from '@/components/performance';
import {
  addSysThemeChangeListener,
  initThemes,
} from '@zenlayer/zen-design/esm/hooks/useThemeSwitch';

(function bootstrap() {
  initThemes({ themes: ['dark', 'light'] });
  addSysThemeChangeListener();

  const container = document.getElementById('root-status') as HTMLElement;
  createRoot(container).render(<App />);
})();
