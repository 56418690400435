import {
  getLocale,
  isChromeSwitchOpened,
  setAllLangJSON,
} from '@zenlayer/zen-intl18';
import { fetch } from '../request';

type LangDomain = {
  en_text: {
    id: number;
    domain: 'en';
    inherited: number;
    text: {
      [key: string]: string;
    };
  };
  zh_text: {
    id: number;
    domain: string;
    inherited?: number;
    text: {
      [key: string]: string;
    };
  };
};

type LangDomainInherited = {
  id: number;
  domain: 'zh';
  inherited?: {
    id: number;
    domain: string;
    text: {
      [key: string]: string;
    };
  };
  text: {
    [key: string]: string;
  };
};

async function getInheritedLang(id?: number) {
  if (typeof id !== 'number') {
    return {};
  }
  const { data } = await fetch<LangDomainInherited[]>({
    method: 'get',
    url: '/cms/text-ens',
    params: {
      id_eq: id,
    },
  });
  const lang = data[0];
  return {
    ...lang.inherited?.text,
    ...lang.text,
  };
}

export async function getLangFromCMS() {
  let res = await fetch<LangDomain[]>({
    method: 'get',
    url: '/cms/console-envs',
    params: {
      name_eq: 'status',
    },
  });
  const locale = getLocale();
  if (!res.data.length) {
    return null;
  }
  const { en_text, zh_text } = res.data[0];
  const chromeExtensionOpened = isChromeSwitchOpened();
  const [inheritedEn, inheritedZh] = await Promise.all([
    getInheritedLang(
      !chromeExtensionOpened && locale !== 'en' ? undefined : en_text.inherited
    ),
    getInheritedLang(
      !chromeExtensionOpened && locale !== 'zh' ? undefined : zh_text.inherited
    ),
  ]);
  const en = {
    id: en_text.id,
    domain: en_text.domain,
    text: { ...inheritedEn, ...en_text.text },
  };
  const zh = {
    id: zh_text.id,
    domain: zh_text.domain,
    text: { ...inheritedZh, ...zh_text.text },
  };
  if (chromeExtensionOpened) {
    setAllLangJSON({ en, zh });
  }
  return locale === 'en' ? en : zh;
}

export function getLangFromDevStaticPath() {
  return fetch<{
    [key: string]: string;
  }>({ method: 'get', url: `/public/text.json` });
}

const CDN_HOST: string = window._zen_env_?.public_path
  ? window._zen_env_.public_path
  : '';

export function getLangFromStaticPath(locale?: 'en' | 'zh') {
  const LOCALE_PATH =
    locale === 'zh' ? `/public/text-zh.json` : `/public/text.json`;
  return fetch<{
    self?: { [key: string]: string } | null;
    inherited?: { [key: string]: string } | null;
  }>({
    method: 'get',
    withCredentials: false,
    url: `${CDN_HOST}${LOCALE_PATH}`,
    cache: true,
    cacheDataEncrypt: false,
    interpretHeader: false,
    cacheTakeover: false,
  });
}
