/**
 * 通用请求函数
 */
import {
  ZenFetchRequestConfig,
  zenFetch,
  zenSigleton,
} from '@zenlayer/portal-utils';
import { Modal } from '@zenlayer/zen-design';

let abortController = new AbortController();

export const axiosAbortController = {
  reset() {
    abortController = new AbortController();
  },
  abort() {
    abortController.abort();
  },
};

export function fetch<T>(params: ZenFetchRequestConfig) {
  const reqResourceGroupUid = params.headers?.['X-Zenconsole-Group-Id'];
  if (reqResourceGroupUid) {
    params.headers = {
      ...(params.headers as any),
      'X-Zenconsole-Group-Id':
        reqResourceGroupUid === 'true' || reqResourceGroupUid === true
          ? zenSigleton.get('resourceGroupUid')
          : reqResourceGroupUid,
    };
  }
  if (!params.signal) {
    params.signal = abortController.signal;
  }
  return zenFetch.request<T>(params).then((res) => {
    if (res.status === 403) {
      Modal.destroyAll();
    }
    return res;
  });
}

export function request<T = any>(params: ZenFetchRequestConfig) {
  return fetch<RequestRes<T>>(params).then((res) => {
    const resD = res?.data ?? <RequestRes<T>>{};
    return {
      ...resD,
      data: resD?.data ?? <T>{},
    };
  });
}

export const requestFactory = function (prefix: string) {
  return function <T>(params: ZenFetchRequestConfig) {
    return request<T>({ ...params, url: prefix + params.url });
  };
};
