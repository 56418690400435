import { Context_gs, GlobalContext } from '@/App';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

const ContextHOC: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <React.Suspense>
      <BrowserRouter>
        <GlobalContext.Provider value={Context_gs as IGlobalContext}>
          {props.children}
        </GlobalContext.Provider>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default ContextHOC;
